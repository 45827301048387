import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { getToken } from "./cookies";

const fakeApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
const axiosHttp = axios.create({
  baseURL: process.env.REACT_APP_XPD_API_URL,
});

axiosHttp.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    // Do something before request is sent
    const token = getToken();
    if (token && config.url !== "api-token-auth/") {
      config.headers = {
        ...config.headers,
        Authorization: `Token ${token}`,
      };
    }
    return config;
  },
  function (error: AxiosError) {
    // Do something with request error
    return Promise.reject(error);
  },
);

//interceptors response
axiosHttp.interceptors.response.use(
  (res: AxiosResponse) => {
    /// Do something with response
    return res;
  },
  (error: AxiosError) => {
    // sem autorização
    if (error.response?.status === 401 || error.response?.status === 0) {
      window.location.href = "/login";
    }
    return Promise.reject(error);
  },
);

export const api = axiosHttp;

export default fakeApi;
