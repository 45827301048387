import React from 'react';
import {
  Alert, Box, Button, CircularProgress, Dialog,
  Fade, TextField, Typography, Collapse, IconButton
} from '@mui/material';
import { Container } from '@mui/system';
import RzxLogo from '../../assets/images/logo1.png';
import './FormDialogStyles.scss';
import { iMailing } from '../../@types/plataforma.type';
import { createMailingApi, updateMailingApi } from '../../services/mailing';
import CloseIcon from '@mui/icons-material/Close';

interface iFormDialog {
  open: boolean;
  handleClose: (platform?: iMailing) => void;
  mailEdit: iMailing | null;
  plataform: string | null;
}

const MailingFormDialog: React.FC<iFormDialog> = ({ open, handleClose, mailEdit, plataform }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [name, setName] = React.useState<String>('');
  const [email, setEmail] = React.useState<String>('');
  const [genericError, setGenericError] = React.useState<{ detail: string, hasNotAcceptable: boolean }>({ detail: '', hasNotAcceptable: false });
  const [openAlert, setOpenAlert] = React.useState(true);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setName(value);
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setEmail(value);
  }

  const handleCloseModal = (plataform?: iMailing) => {
    setGenericError({ detail: '', hasNotAcceptable: false })
    handleClose(plataform)
  }

  const handleCatchError = (err: any) => {
    setOpenAlert(true)
    setGenericError({ detail: err.response.data.detail, hasNotAcceptable: true })
    setLoading(false);
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true)
    const data = new FormData(event.currentTarget);
    const dados: iMailing = {
      name: data.get('name')?.toString() || '',
      email: data.get('email')?.toString() || '',
      plataforma: plataform
    }
    const onResolve = (res: any) => {
      setLoading(false)
      handleClose(res.data)
    }
    if (!mailEdit) {
      createMailingApi(dados).then(onResolve).catch(handleCatchError);
    } else {
      updateMailingApi({ ...dados, id: mailEdit?.id }, plataform).then(onResolve).catch(handleCatchError)
    }
  }

  React.useEffect(() => {
    if (mailEdit) {
      setName(mailEdit.name)
      setEmail(mailEdit.email)
    } else {
      setName('')
      setEmail('')
    }
  }, [mailEdit]);

  return (
    <Dialog open={open} onClose={() => handleCloseModal()} className="form-dialog-user">
      <Container component="main" maxWidth="sm" className="form-container">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box className="box-img">
            <img src={RzxLogo} alt="Logo" />
          </Box>
          <Typography component="h1" variant="h5">
            Register Destination
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoFocus
              color="primary"
              variant="filled"
              value={name}
              onChange={handleNameChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoFocus
              color="primary"
              variant="filled"
              value={email}
              onChange={handleEmailChange}
            />
            {
              genericError.hasNotAcceptable ?
                <Collapse in={openAlert}>
                  <Alert severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setOpenAlert(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }>{genericError?.detail}</Alert>
                </Collapse> : <></>
            }
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Save
              <Fade
                in={loading}
                style={{
                  transitionDelay: loading ? '300ms' : '0ms',
                  marginLeft: 10
                }}
                unmountOnExit
              >
                <CircularProgress color="inherit" size={15} />
              </Fade>
            </Button>
          </Box>
        </Box>

      </Container>

    </Dialog>

  )
}
export default MailingFormDialog