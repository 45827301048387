import { iTimer } from "../@types/plataforma.type";
import { api } from "./api";

export const getSelecaoTimerApi = () => {
  return api.get("reports/timer_daily_reports/").then((res) => res.data);
};

export const getTimerApi = () => {
  return api.get("reports/timer_daily_reports/").then((res) => res.data);
};

export const createTimerApi = (timer: iTimer) => {
  return api.post("reports/timer_daily_reports/", timer);
};

export const updateTimerApi = (timer: iTimer) => {
  return api.put(`reports/timer_daily_reports/${timer.id}/`, timer);
};

export const deleteTimerApi = (timer: iTimer) => {
  return api.delete(`reports/timer_daily_reports/${timer.id}/`);
};
