import { createTheme } from "@mui/material/styles";

export const appTheme = createTheme({
    palette: {
        primary: {
          light: '#fff',
          main: '#134F62',
        },
        secondary: {
          main: '#f50057',
        },
      }
});