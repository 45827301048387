import React from "react";
import { iNpt } from "../../@types/efficiency.type";
import './NPTTableStyle.scss';
import EfficiencyTable from "../EfficiencyTable/EfficiencyTable";

const NPTTable: React.FC<{dados: iNpt[]}>=({dados})=>{
    return(
        <div className="npt-table" style={{width:'99%'}}>
            <table className="text-white" style={{width:'100%'}}>
                {dados?.map((npt: iNpt, index: number) => 
              (<>
                <thead>
                <tr className="theader-texto-cinza">
                    <th>Category</th>
                    <th>NPT</th>
                    <th style={{width:'30%'}}>Description</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Duration</th>
                    <th>Duration in Last 24h</th>
                </tr>
                </thead>
                <tbody>
                  <tr className="text-white bg-azul">
                    <td>{npt.category}</td>
                    <td>{npt.npt}</td>
                    <td>{npt.activity_name}</td>
                    <td>{npt.start_time}</td>
                    <td>{npt.end_time}</td>
                    <td>{npt.total_time}</td>
                    <td>{npt.total_accumulated}</td>
                    
                </tr>
                <tr className="Events"><td colSpan={7}>
                    <EfficiencyTable dados={{
                        total_npi:npt.total_tnpi,
                        total_accumulated:npt.total_accumulated,
                        efficiency_list:npt.failure_activities}}/>
                </td></tr>
                </tbody>
                </>)
                )}
            </table>
        </div>
    )
}

export default NPTTable