import React from "react";
import './PocoInfoStyle.scss';

const PocoInfo: React.FC<{pocoinfos?:{rig:string, well:string, client:string}}>=({pocoinfos})=>{
    return(



<div className="flex-1 font-small info-base text-white">
  <div className="row poco-info">
      <label htmlFor="info-rig">RIG</label>
      <span>{pocoinfos?.rig}</span>
  </div>
  <div className="row poco-info">
      <label htmlFor="info-well">WELL</label>
      <span>{pocoinfos?.well}</span>
  </div>
  <div className="row poco-info">
      <label htmlFor="info-client">CLIENT</label>
      <span>{pocoinfos?.client}</span>
  </div>
</div>

)}

export default PocoInfo