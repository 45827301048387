import React from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Button, 
  styled, 
  Table, 
  TableBody, 
  TableHead, 
  TableRow, 
  ThemeProvider,
  TextField,
  IconButton
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { appTheme } from '../../styles/tema';
import './MailingReportsStyles.scss';

import { iMailing } from '../../@types/plataforma.type';
import { useSearchParams } from 'react-router-dom';
import BoxLogo from '../../components/BoxLogo/BoxLogo';
import MailingFormDialog from '../../components/MailingFormDialog/MailingFormDialog';
import DeleteDialog from '../../components/DeleteDialog/DeleteDialog';
import { deleteMailingApi, getMailingApi } from '../../services/mailing';
import Pagination from "@mui/material/Pagination";
import { countPages } from '../../utils/functions';


const MailingReports: React.FC = () => {
  const [mails, setMail] = React.useState<iMailing[]>([])
  const [mailEdit, setMailEdit] = React.useState<iMailing | null>(null)
  const [mailDelete, setMailDelete] = React.useState<iMailing | null>(null)
  const [openFormDialog, setOpenFormDialog] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState<boolean>(false);
  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState<number>(1);
  const [loading, setLoading] = React.useState(false);
  const [SearchQuery, setSearchQuery] = React.useState<string>('')


  const history = useNavigate()
  const [params] = useSearchParams()

  const getMail = (search?: string) => {
    getMailingApi(params.get('plataforma'), page, search).then(res => {
      setCount(countPages(res.count))
      setMail(res.results)
    })

  }

  const handleClickSearch = () => {
    setPage(1)
    getMail(SearchQuery)
  }

  /** ao fechar o Dialog, verifica se teve alteração de plataforma, se tiver verifica
  se é nova plataforma ou se foi alteração de existente e coloca essa alteração
  na lista de plataforma */
  const handleClose = (mail?: iMailing) => { //TODO: falha ao 
    setOpenFormDialog(false);
    if (mailEdit && mail) {
      const newList = mails.map(p => p.id === mail?.id ? mail : p)
      setMail(newList)
    } else if (mail) {
      const newList = [...mails, mail]
      setMail(newList)
    }
  };

  const handleClickEditButton = (mail: iMailing) => {
    setMailEdit(mail);
    setOpenFormDialog(true);
  }

  const handleDeleteButton = (mail: iMailing) => {
    setMailDelete(mail);
    setOpenDeleteDialog(true);
  }

  /** Ao deletar, retira da lista de plataforma a plataforma exlcuida */
  const handleDeleteConfirm = (mailToDelete: iMailing) => {
    deleteMailingApi(mailToDelete, mailToDelete.plataforma).then(() => {
      const newList = mails.filter(p => mailToDelete.id !== p.id)
      setMail(newList);
      setOpenDeleteDialog(false);
    })
  }

  const handleClickOpen = () => {
    setOpenFormDialog(true);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const StyledTableCell = styled(TableCell)(({ theme }: { theme: any }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    }
  }));

  React.useEffect(() => {
    getMail()
  }, [page]);

  return (
    <ThemeProvider theme={appTheme}>
      <Box className="plataformas-page">
        <BoxLogo />
        <Box sx={{ width: '100%', marginBottom: 1 }}>
          <Button variant="contained" onClick={() => history(-1)} sx={{ float: 'left' }}
          >
            Back
          </Button>
          <div style={{ float: "right" }}>
            <>
              <TextField
                id="search-bar"
                className="text"
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchQuery(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleClickSearch()
                  }
                }}
                label="Enter sender"
                variant="outlined"
                placeholder="Search..."
                size="small"
                value={SearchQuery}
                key='searchBar'
              />
              <IconButton aria-label="search" onClick={handleClickSearch}>
                <SearchIcon />
              </IconButton>
            </>
            <Button
              variant="contained"
              onClick={handleClickOpen}
              sx={{ float: "right" }}
            >
              New
            </Button>
          </div>
        </Box>
        <Table size="small" className="bg-branco">
          <TableHead style={{ backgroundColor: 'rgba(220, 220, 220, 0.5)' }}>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mails.map((mail: iMailing) => (
              <TableRow key={mail.id}>
                <TableCell>{mail.name}</TableCell>
                <TableCell>{mail.email}</TableCell>
                <TableCell>
                  <Button onClick={() => handleClickEditButton(mail)}>
                    <BorderColorIcon />
                  </Button>
                  <Button onClick={() => handleDeleteButton(mail)} >
                    <DeleteIcon color="error" ></DeleteIcon>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination count={count} page={page} onChange={handleChange} />
      </Box>
      <MailingFormDialog
        open={openFormDialog}
        handleClose={handleClose}
        mailEdit={mailEdit}
        plataform={params.get('plataforma')}
      />
      <DeleteDialog
        openDeleteDialog={openDeleteDialog}
        headerText='Delete Platform'
        bodytext={`Do you really want to delete the platform${' ' + mailDelete?.id || ''}?`}
        onDelete={() => mailDelete && handleDeleteConfirm(mailDelete)}
        onCancel={() => setOpenDeleteDialog(false)}
      />
    </ThemeProvider>
  )
}

export default MailingReports;