import React from "react";
import './GraphPieGroupStyle.scss';
import GraphPie from "./GraphPie/GraphPie";
import { iPieChart } from "../../@types/efficiency.type";


interface iPieGraphGroup{
    failures: iPieChart
    lessons: iPieChart

}

const GraphPieGroup: React.FC<iPieGraphGroup> = ({ failures, lessons }) => {
    return (
      <div className="PieGraphGroup d-flex flex-78 mx-3">
        {!lessons.values || lessons.values.length === 0 ? (
          <p className="NoData BreakGraficos">No suboperation on date range</p>
        ) : (
          <GraphPie
            values={lessons.values}
            labels={lessons.labels}
            text={lessons.text}
            title="SubOperations"
          />
        )}
  
        {!failures.values || failures.values.length === 0 ? (
          <p className="NoData BreakGraficos">No failures/delays on date range</p>
        ) : (
          <GraphPie
            values={failures.values}
            labels={failures.labels}
            text={failures.text}
            title="Failures/Delay"
          />
        )}
      </div>
    );
  };

export default GraphPieGroup