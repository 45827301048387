import React from "react";
import ModeStandbyIcon from "@mui/icons-material/ModeStandby";
import "./TopDrive.scss";
import { connect } from "react-redux";
import { iCurrentOperation } from "../../../@types/currentOperation.type";
import { CircularProgress } from "@mui/material";

interface iMarcacao {
  color:
    | "inherit"
    | "disabled"
    | "action"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | undefined;
  label: string;
  posicao: number;
  id?: number;
}
const TopDrive: React.FC<{ topdrive: any[]; loading: boolean }> = ({
  topdrive,
  loading,
}) => {
  const qtdMetros = 41;

  const range = (n: number) => {
    return Array.from(Array(n).keys()).reverse();
  };

  const getPositionpercent = (posicao: number) => {
    const precisao = posicao - Math.trunc(posicao);
    return precisao * 100;
  };

  const getWidthLabel = (label: string) => {
    const dictWidth: { [key: string]: number } = {
      CURRENT: 25,
      TARGET: 50,
      "PIPE LIMIT": 77,
      "UPPER STOP": 97,
      "STICK UP": 97,
    };
    return dictWidth[label];
  };

  const DivMarcacao = (
    m?: iMarcacao,
    index: number = 0,
    qtdMts: number = 0,
  ) => {
    if (!m) return <></>;
    return (
      <div
        key={index}
        className={`text-left flex-1 d-flex mark ${m.color} w-100 h-100`}
      >
        <ModeStandbyIcon
          className="icone-posicao"
          style={{ top: `-${getPositionpercent(m.posicao || 0) + 55}%` }}
          color={m.color || "inherit"}
          sx={{ fontSize: 25 }}
        />
        {labelTime(qtdMts)}
        {index === 0 && <span className="text-white borda-esquerda"></span>}
        <span
          className="mark-mensagem"
          style={{
            position: "absolute",
            marginRight: index * 2 + "rem",
            marginLeft: index > 0 ? ".5rem" : "5px",
            bottom: `${getPositionpercent(m?.posicao)}%`,
            right: `${index * 5}%`,
            left: 0,
            width: `${getWidthLabel(m.label)}%`,
            // matematica para fazer a linha colorida não ultrapassar o ponto
            // 97 para não ficar me 100% e encostar no extremo da celula
            // 19 (%) para cada item quando estiver na mesma casa com diferente decimais, ficarem lado a lado
            // para cada item a mais, fica 19% a menos de largura
          }}
        >
          <span className="span-mark-message">{m.label}</span>
          {m?.posicao.toFixed(2) || ""}
        </span>
      </div>
    );
  };
  const labelTime = (qtdMts: number) => {
    var now = topdrive?.find((target) => target.label === "CURRENT") || null;
    var tPos = topdrive?.find((target) => target.label === "TARGET") || null;
    var colorLabel = "transparent";
    if (
      now?.posicao < tPos?.posicao &&
      now?.posicao <= qtdMts &&
      tPos?.posicao >= qtdMts
    ) {
      colorLabel = "blue";
    } else if (
      now?.posicao > tPos?.posicao &&
      now?.posicao >= qtdMts &&
      tPos?.posicao <= qtdMts
    ) {
      colorLabel = "red";
    }
    return (
      <span style={{ width: "0.5rem", backgroundColor: colorLabel }}></span>
    );
  };
  return (
    <section className="top-drive d-flex flex-1 mr-1 mb-1 p-relative">
      {loading ? (
        <div style={{ position: "absolute", right: 5, top: 5 }}>
          <CircularProgress size="15px" />
        </div>
      ) : (
        <></>
      )}
      <div className="d-flex flex-1 flex-column font-xsmall p-1">
        {range(qtdMetros).map((i) => (
          <div className="top-drive-item d-flex flex-1" key={i}>
            <div className="topdrive-index-cell p-relative ">
              <span className="text-white text-right mr-1 topdrive-indice">
                {i}
              </span>
            </div>
            <div className="d-flex flex-1">
              <span className="text-white borda-direita"></span>

              {topdrive.some((m) => Math.trunc(m.posicao) === i) ? (
                <div className="marks p-relative" style={{ width: "97%" }}>
                  {topdrive
                    .filter((value) => Math.trunc(value.posicao) === i)
                    .map((filtrado, index) => DivMarcacao(filtrado, index, i))}
                </div>
              ) : (
                <>
                  {" "}
                  {labelTime(i)}
                  <span className={`text-white borda-esquerda`}></span>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default connect(
  (state: {
    topdrive: any[];
    graficos: iCurrentOperation;
    loading: { topdrive: boolean };
  }) => ({
    topdrive: state.topdrive,
    loading: state.loading.topdrive,
  }),
)(TopDrive);
