import React from "react";
import { iEfficiency, iEfficiencyData } from "../../@types/efficiency.type";
import './EfficiencyTableStyle.scss';

const EfficiencyTable: React.FC<{dados?: iEfficiency|null}>=({dados})=>{
    return(
        <div className="efficiency-table" style={{width:'99%'}}>
            <table className="text-cinza-escuro" style={{width:'100%'}}>
                <thead>
                <tr className="theader-texto-cinza bg-cinza">
                    <th style={{width:'12%'}}>Operation</th>
                    <th style={{width:'12%'}}>Activity</th>
                    <th style={{width:'12%'}}>Start</th>
                    <th style={{width:'12%'}}>End</th>
                    <th style={{width:'5%'}}>Trip</th>
                    <th style={{width:'5%'}}>Event Qty</th>
                    <th style={{width:'7%'}}>Total Time</th>
                    <th style={{width:'7%'}}>TNPI</th>
                    <th style={{width:'5%'}}>Efficiency*</th>
                    <th><span className="barraEfficiency" style={{paddingRight:'80%'}}>0%</span><span>100%</span></th>
                </tr>
                </thead>
                <tbody>
                {dados?.efficiency_list?.map((efficiency: iEfficiencyData, index: number) => 
              (
                <tr className="text-white" key={index}>
                    <td>{efficiency.operation_name}</td>
                    <td>{efficiency.activity_name}</td>
                    <td>{efficiency.starttime}</td>
                    <td>{efficiency.endtime}</td>
                    <td>{efficiency.trip}</td>
                    <td>{efficiency.quantity}</td>
                    <td>{efficiency.total_time}</td>
                    <td>{efficiency.tnpi}</td>
                    <td>{efficiency.percentage}{efficiency.percentage !== '---'? '%':''}</td>
                    <td className="efficiency-column">
                        <div className="progress-bar" style={{width:`${efficiency.percentage !== '---'? efficiency.percentage: 0}%`}}>
                        </div>
                    </td>
                </tr>)
                )}
                </tbody>
                <tfoot>
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Accumulated</th>
                    <th></th>
                    <th></th>
                    <th>{dados?.total_accumulated}</th>
                    <th>{dados?.total_npi}</th>
                    <th></th>
                    <th></th>
                </tr>
                </tfoot>
            </table>
        </div>
    )
}

export default EfficiencyTable
