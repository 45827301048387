import React from "react";
import Plot from "react-plotly.js";
import { iChartdata } from "../../../../@types/reportgraphs.type";
import './ReportGraphStyle.scss';

const axis: iChartdata = {x:[], y:[], bar_colors:[], voptimum_target: 0, vstandard_target: 0, vpoor_target: 0, label_list:[], operation_name: []}
    const eventtypes = {
        slip_to_slip: axis,
        pipe_move: axis,
        gross_speed: axis
        }
      const initial_bha={
        open_hole: eventtypes,
        cased: eventtypes,
        drilling: eventtypes,
        }
      const initial_casing={
        settlement: eventtypes,
        casing: eventtypes,
        belowshoedepth: eventtypes
        }
      const initial_riser={
        ascend: eventtypes,
        descend: eventtypes,
        }
        const initial_time=axis
interface iReportGraph {
    data: any,
    startdate: String,
    enddate: String,
    bgclassname: String,
    labelname: React.ReactNode,
    attributename: string,
    state: string,
    exibe_grafico_avg?: boolean,
    label_hover?: string,

}
const ReportGraph: React.FC<iReportGraph>=({
        data,
        startdate,
        enddate,
        bgclassname,
        labelname,
        attributename,
        state,
        exibe_grafico_avg=false,
        label_hover='Duration(min)'
    })=>{
      const create_custom_label=(operation_name:string[], startbitdepth: number[])=>{
        const array_final=operation_name.map((value: string, indice: number)=>[value, startbitdepth[indice]]
        )
        return array_final
      }
    return(
        <div className="Graficos flex-78 mx-3 d-flex">
              
        <div className={"SideLabel "+bgclassname}>
          {labelname}
        </div>
          <div className="Grafico flex-1 flex-graph-print d-flex flex-column">
            <Plot
              divId={`id_${state}_${attributename}`}
              data={[
                // Horizontal Line Vaverage
                {
                  type: 'scatter',
                  x: ['2000-07-01', '2099-07-01'],
                  y: [data[state][attributename]['vaverage_target'], data[state][attributename]['vaverage_target']],
                  mode: 'lines',
                  name: "vaverage: " + data[state][attributename]['vaverage_target'],
                  line: {
                   width: 2,
                   color: 'rgb(160, 160, 160)',
                   dash: 'dash'
                         }
                 },
                // Horizontal Line Voptimum
                {
                 type: 'scatter',
                 x: ['2000-07-01', '2099-07-01'],
                 y: [data[state][attributename]['voptimum_target'], data[state][attributename]['voptimum_target']],
                 mode: 'lines',
                 name: "voptimum: " + data[state][attributename]['voptimum_target'],
                 line: {
                  width: 2,
                  color: 'rgb(137, 207, 240)',
                  dash: 'dash'
                        }
                },
                // Horizontal Line Vstandard
                {
                  type: 'scatter',
                  x: ['2000-07-01', '2099-07-01'],
                  y: [data[state][attributename]['vstandard_target'], data[state][attributename]['vstandard_target']],
                  mode: 'lines',
                  name: "vstandard: " + data[state][attributename]['vstandard_target'],
                  line: {
                   width: 2,
                   color: 'rgb(208, 166, 20)',
                   dash: 'dash'
                         }
                 },
                 // Horizontal Line Vpoor
                 {
                  type: 'scatter',
                  x: ['2000-07-01', '2099-07-01'],
                  y: [data[state][attributename]['vpoor_target'], data[state][attributename]['vpoor_target']],
                  mode: 'lines',
                  name: "vpoor: " + data[state][attributename]['vpoor_target'],
                  line: {
                   width: 2,
                   color: 'rgb(191, 2, 2)',
                   dash: 'dash'
                         }
                 },
                {type: 'bar',
                 x: data[state][attributename]['x'],
                 y: data[state][attributename]['y'],
                 width: 300000,
                 showlegend: false,
                 marker:{
                  color: data[state][attributename]['bar_colors'],
                  line: {
                    color: 'rgb(0,0,0)',
                    width: 0.5
                  }
                        },
                 textposition: 'none',
                 textfont: {
                  size: 28,
                },
                 customdata: create_custom_label(data[state][attributename]['operation_name'], data[state][attributename]['startbitdepth']),
                 text: data[state][attributename]['label_list'],
                 hovertemplate:'<b>%{customdata[0]}</b>' +
                               `<br><b>Start bit depth</b>: %{customdata[1]}` +
                               '<br><b>Start time</b>: %{x}<br>' +
                               `<b>${label_hover}</b>: %{text}<extra></extra>`
                },
              ]}
              config={{
                displaylogo: false,
                responsive: true
              }}
              layout={{
                legend: {font:{size:15}},
                barmode: 'relative',
                showlegend: true,
                height: 250,
                bargap: 0,
                bargroupgap: 0,
                colorway: ['#1f77b4'],
                margin: {
                  t: 50,
                  b: 50,
                  l: 40,
                  r: 20},
                  xaxis:
                  {
                    autorange: false,
                    range: [startdate, enddate],
                    tickfont: {
                      size: 18,
                    },
                    dtick: 7200000,
                  },
                  yaxis:
                  {
                    autorange: false,
                    range: [0, data[state][attributename]['vaverage_target']*2 ],
                    tickfont: {
                      size: 16,
                    },
                  
                  },
                    
                    
              } }
              />
            {/* Graficos de Average */}
            {exibe_grafico_avg?
            <Plot
            divId={`id_${state}_${attributename}`}
            data={[
              // Horizontal Line Daily Avg
              {
                type: 'scatter',
                x: ['2000-07-01', '2099-07-01'],
                y: [data[state][attributename]['avg_y'][0], data[state][attributename]['avg_y'][0]],
                mode: 'lines',
                name: "average: " + data[state][attributename]['avg_y'][0],
                line: {
                 width: 2,
                 color: 'rgb(160, 160, 160)',
                 dash: 'dash'
                       }
               },
              // Horizontal Line Day Shift Avg
              {
               type: 'scatter',
               x: ['2000-07-01', '2099-07-01'],
               y: [data[state][attributename]['avg_y'][1], data[state][attributename]['avg_y'][1]],
               mode: 'lines',
               name: "day shift avg: " + data[state][attributename]['avg_y'][1],
               line: {
                width: 2,
                color: 'rgb(255, 127, 14)',
                dash: 'dash'
                      }
              },
              // Horizontal Line Night Shift Avg
              {
                type: 'scatter',
                x: ['2000-07-01', '2099-07-01'],
                y: [data[state][attributename]['avg_y'][2], data[state][attributename]['avg_y'][2]],
                mode: 'lines',
                name: "night shift avg: " + data[state][attributename]['avg_y'][2],
                line: {
                  width: 2,
                  color: 'rgb(133, 81, 189)',
                  dash: 'dash'
                }
              },
              // Horizontal Line Voptimum
              {
                type: 'scatter',
                x: ['2000-07-01', '2099-07-01'],
                y: [data[state][attributename]['voptimum_target'], data[state][attributename]['voptimum_target']],
                mode: 'lines',
                name: "voptimum: " + data[state][attributename]['voptimum_target'],
                line: {
                 width: 2,
                 color: 'rgb(45, 138, 224)',
                 dash: 'dash'
                       }
               },
              {
                type: 'bar',
                x: data[state][attributename]['avg_x_list'],
                y: data[state][attributename]['avg_y_list'],
                width: 2000000,
                showlegend: false,
                marker:{
                  color: 'rgb(94, 94, 94)',
                  line: {
                    color: 'rgb(0,0,0)',
                    width: 0.5
                  }
                      },
                customdata: data[state][attributename]['qty_list'],
                text: data[state][attributename]['avg_y_label'],
                textposition: 'outside',
                texttemplate: '%{customdata}',
                textfont: {
                  size: 18,
                },
                
                
                hovertemplate:
                              '<br><b>Hour</b>: %{x}<br>' +
                              `<b>${label_hover}</b>: %{text}<extra></extra>`+
                              '<br><b>Quantity</b>: %{customdata}'
                },
            ]}
            config={{
              displaylogo: false,
              responsive: true
            }}
            layout={{
              legend: {font:{size:15}},
              barmode: 'relative',
              showlegend: true,
              height: 250,
              bargap: 0,
              bargroupgap: 0,
              colorway: ['#1f77b4'],
              margin: {
                t: 50,
                b: 50,
                l: 40,
                r: 20},
              xaxis:
              {
                autorange: false,
                range: [startdate, enddate],
                tickfont: {
                  size: 18,
                },
                dtick: 7200000
              },
              yaxis:
              {
                autorange: false,
                range: [0, data[state][attributename]['avg_y'][0]*4],
                tickfont: {
                  size: 16,
                },
              },
                  
            } }
            />
              :<></>}
          </div>
      </div>
      
    )
}

export default ReportGraph