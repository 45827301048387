import React from "react";
import Grafico from "./Grafico";
import GraficoInfos from "./GraficoInfos";
import './OperacaoGrafico.scss';
import { connect } from "react-redux";
import { iGraficos } from "../../../@types/graficosOperacoes.type";
import GraficoLinha from "./GraficoLinha";
import { CircularProgress } from "@mui/material";


const OperacaoGraficos: React.FC<{graficos: iGraficos, loading: boolean}> = ({graficos, loading}) => {

  const verde = "#19803C";
  const vermelho = "#8B0F2D";
  const azul = "#2E8BA8";
  
  return (
    <div className="tabelas-grafico-operacao d-flex flex-1 ml-1 mb-1 p-relative">
      {loading?
      <div style={{position: "absolute", right: 5, top: 5}}><CircularProgress size="15px" /></div>: <></>}
      <table className="font-xsmall text-white w-100" cellSpacing="0" cellPadding="0">
        <thead>
          <tr>
            <th></th>
            <th className="th-span"><span>Slip to Slip Time</span></th>
            <th className="th-span"><span>Pipe Move Speed</span></th>
            <th className="th-span"><span>Gross Speed</span></th>
          </tr>
        </thead>

        {/* Linha 1 dos gráficos */}
        {graficos.current_operation_type === 'casing' ? (
          <GraficoLinha 
          type_label="Settlement String"
          type={graficos?.settlement}
          type_id="settlement"
          ></GraficoLinha>
        ) :
        (graficos.current_operation_type === 'riser' ?
        <GraficoLinha 
          type_label="Riser Ascend"
          type={graficos?.riserascend}
          type_id="riserAscend"
          ></GraficoLinha>
          :
        <GraficoLinha 
          type_label="Cased Well"
          type={graficos?.cased}
          type_id="cased"
          ></GraficoLinha>

        )
      }
      
      {/* Linha 2 dos gráficos */}
      {graficos.current_operation_type === 'riser' ? (
        <GraficoLinha 
          type_label="Riser Descend"
          type={graficos?.riserdescend}
          type_id="riserDescend"
        ></GraficoLinha>
        ) :
        (<GraficoLinha 
          type_label="Open Hole"
          type={graficos?.openhole}
          type_id="openHole"
          ></GraficoLinha>
        )
      }

      {/* Linha 3 dos gráficos */}
      {graficos.current_operation_type === 'casing' ? (
        <GraficoLinha 
          type_label="Casing"
          type={graficos?.casing}
          type_id="casing"
        ></GraficoLinha>
      ) : 
      (graficos.current_operation_type === 'riser' ? (
        <div className='div-vazia'>

        </div>
      ) :
        <GraficoLinha 
          type_label="Drilling"
          type={graficos?.drilling}
          type_id="drilling"
        ></GraficoLinha>
        )
      }
      </table> 
    </div>
  )
}

const defaultGraficos = {
  current_operation_type: '',
  openhole: null,
  cased: null,
  drilling: null
}
export default connect((state: {graficos: any, loading: {graficos: boolean}}) => (
  {
    graficos:state.graficos || defaultGraficos,
    loading: state.loading.graficos,
  }
  ))(OperacaoGraficos);
