import { iGraficos } from "../../../@types/graficosOperacoes.type";
import Grafico from "./Grafico"
import GraficoInfos from "./GraficoInfos"

interface iGraficoLinha{
    type_label?: string;
    type: any;
    type_id: string;
}

const GraficoLinha: React.FC<iGraficoLinha> = ({type_label, type, type_id}) => {
    const verde = "#19803C";
    const vermelho = "#8B0F2D";
    const azul = "#2E8BA8";

    return ( <tbody>
        {/*  Settlement String */}
      <tr>
        <td rowSpan={2}  className="rotate"><div><span>{type_label}</span></div></td>
        <td className="bg-cinza">
          <GraficoInfos 
            goal={type?.slipToSlip?.goal || "0"} 
            average={type?.slipToSlip?.average || "0"} 
            current={type?.slipToSlip?.current || "0"} />
          </td>
        <td className="bg-cinza">
          <GraficoInfos 
            goal={type?.pipeMove?.goal || "0"} 
            average={type?.pipeMove?.average || "0"} 
            current={type?.pipeMove?.current || "0"} />
          </td>
        <td className="bg-cinza">
          <GraficoInfos 
            goal={type?.grossSpeed?.goal || "0"} 
            average={type?.grossSpeed?.average || "0"} 
            current={type?.grossSpeed?.current || "0"} />
          </td>
      </tr>
      {type?.error ? 
        <tr>
          <td colSpan={3}><div className="div-grafico-sem-dados bg-cinza">{type?.error}</div></td>
        </tr> : 
        <tr className="bg-cinza">
          <td>
            <Grafico 
              data={type?.slipToSlip?.data.filter((v:{x:number}) => v.x) || []} 
              color={azul} 
              id={`${type_id}_slipToSlip`}
              referenceLine={type?.slipToSlip?.goal_line} />
            </td>
          <td>
            <Grafico 
              type_chart={'pipemove'}
              data={type?.pipeMove?.data.filter((v:{x:number}) => v.x) || []} 
              color={verde} 
              id={`${type_id}_pipeMove`}
              referenceLine={type?.pipeMove?.goal_line} />
            </td>
          <td>
            <Grafico 
              type_chart={'grossspeed'}
              data={type?.grossSpeed?.data.filter((v:{x:number}) => v.x) || []} 
              color={vermelho} 
              id={`${type_id}_grossSpeed`}
              referenceLine={type?.grossSpeed?.goal_line} />
            </td>
        </tr>}
    </tbody>)
}

export default GraficoLinha