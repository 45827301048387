import React from 'react';
import { Alert, Box, Button, CircularProgress, Collapse, Dialog, 
  Fade, FormControlLabel, IconButton, Switch, TextField, Typography 
} from '@mui/material';
import DesktopTimePicker from "@mui/lab/DesktopTimePicker";
import { Container } from '@mui/system';
import RzxLogo from '../../assets/images/logo1.png';
import './FormDialogStyles.scss';
import { iPlataforma } from '../../@types/plataforma.type';
import { createPlataformaApi, updatePlataformaApi } from '../../services/plataforma';
import Plataforma from '../../pages/Plataforma';
import CloseIcon from '@mui/icons-material/Close';

interface iFormDialog{
  open: boolean;
  handleClose: (platform?: iPlataforma)=>void;
  platformEdit?: iPlataforma | null
}


const PlatformFormDialog: React.FC<iFormDialog> = ({open, handleClose, platformEdit}) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [name, setName] = React.useState<String>('');
    const [db_name, setDb_name] = React.useState<String>('');
    const [ssh_tunnel_port, setSshTunnelPort] = React.useState<string>('');
    const [enabled, setEnabled] = React.useState<boolean>(true);
    const [sendTime, setSendTime] = React.useState<string>('');
    const [genericError, setGenericError] = React.useState<{detail: string, hasNotAcceptable: boolean}>({detail: '', hasNotAcceptable: false});
    const [openAlert, setOpenAlert] = React.useState(true);
    

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setName(value);
  }

  const handleDbNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setDb_name(value);
  }

  const handleSSHTunelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSshTunnelPort(value);
  }

  const handleEnabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked
    setEnabled(value);
  }

  const handleSendTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSendTime(value);
  }

  const handleCloseModal = (plataform?: iPlataforma) => {
    setGenericError({detail: '', hasNotAcceptable: false})
    handleClose(plataform)
  }

  const handleCatchError = (err: any) =>{
    setOpenAlert(true)
    setGenericError({detail: err.response.data.detail, hasNotAcceptable:true})
    setLoading(false);
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true)
    const data = new FormData(event.currentTarget);
    const dados: iPlataforma = {
      name: data.get('name')?.toString() || '',
      db_name: data.get('db_name')?.toString() || '',
      ssh_tunnel_port: data.get('ssh_tunnel_port')?.toString() || '',
      enabled: data.get('enabled') === 'on' ? true : false,
      send_time: data.get('send_time')?.toString() || '',
    }
    const onResolve = (res: any) => {
      setLoading(false)
      handleCloseModal(res.data)
    }
    if (!platformEdit){
      createPlataformaApi(dados).then(onResolve).catch(handleCatchError);
    } else {
      updatePlataformaApi({...dados, id: platformEdit.id}).then(onResolve).catch(handleCatchError)
    }
  }

  React.useEffect(() =>{
    if(platformEdit){
      setName(platformEdit.name)
      setDb_name(platformEdit.db_name)
      setSshTunnelPort(platformEdit.ssh_tunnel_port) //
      setEnabled(platformEdit.enabled)
      setSendTime(platformEdit.send_time)
    } else {
      setName('')
      setDb_name('')
      setSshTunnelPort('')
      setEnabled(true)
      setSendTime('')
    }
  }, [platformEdit]);


  return (
    <Dialog open={open} onClose={()=>handleCloseModal()} className="form-dialog-user">
      <Container component="main" maxWidth="sm" className="form-container">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
        <Box className="box-img">
          <img src={RzxLogo} alt="Logo" />
        </Box>
        <Typography component="h1" variant="h5">
          Register Platform
        </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoFocus
              color="primary"
              variant="filled"
              value={name}
              onChange={handleNameChange}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              id="db_name"
              label="Database name"
              name="db_name"
              color="primary"
              variant="filled"
              value={db_name}
              onChange={handleDbNameChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="ssh_tunnel_port"
              label="SSH tunnel port"
              name="ssh_tunnel_port"
              type="number"
              color="primary"
              variant="filled"
              defaultValue="9003"
              helperText="check avalible ports"
              value={ssh_tunnel_port}
              onChange={handleSSHTunelChange}
            />
            {
                genericError.hasNotAcceptable? 
                <Collapse in={openAlert}>
                <Alert severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }>{genericError?.detail}</Alert>
                </Collapse> : <></>          
            }
            <FormControlLabel 
              label="Enabled" 
              control={
                <Switch 
                  name='enabled' 
                  inputProps={{ 'aria-label': 'controlled' }}
                  onChange={handleEnabledChange} 
                  checked={enabled}/>
                } 
            />

            <TextField
              margin="normal"
              id="send_time"
              fullWidth
              type="time"
              label="time to send reports2"
              name="send_time"
              color="primary"
              variant="filled"
              value={sendTime}
              onChange={handleSendTimeChange}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Save
              <Fade
                in={loading}
                style={{
                  transitionDelay: loading ? '300ms' : '0ms',
                  marginLeft: 10
                }}
                unmountOnExit
              >
                <CircularProgress color="inherit" size={15} />
              </Fade>
            </Button>
          </Box>
        </Box>
      </Container>
    </Dialog>
  )
}

  export default PlatformFormDialog
