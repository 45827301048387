import {api} from "./api"

export const getDadosPocoApi = (plataforma: string | null) => {
    return api.get('dadospoco/', {params:{plataforma}}).then(res => {
        return {
            ...res.data,
            pocoinfos: res?.data?.pocoinfos?.length ? res.data.pocoinfos[0] : []
        }
    })
}
export const getConsistencyApi = (plataforma: string | null) => {
    return api.get('consistency/', {params:{plataforma}}).then(res => res.data)
}

export const getCurrentCrewApi = (plataforma: string | null) => {
    return api.get('currentcrew/', {params:{plataforma}}).then(res => res.data)
}

export const getPocoInfosApi = (plataforma: string | null) => {
    return api.get('pocoinfo/', {params:{plataforma}}).then(res => res.data)
}