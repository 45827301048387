import {api} from './api';


export const getMetasApi = (plataforma: string | null): Promise<any> => {
    
    return api.get('metas/', {params:{plataforma}}).then(response => {
        const calculo_currentprogress = response.data.current_progress
        const calculo_totalprogress = response.data.total_progress
        const transformar_em_porcentagem = (calculo_currentprogress/calculo_totalprogress)*100
        
      return {dados: response.data ,porcentagem: transformar_em_porcentagem}
    })
}