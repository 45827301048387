import React from 'react';
import NavBar from '../../components/NavBar/NavBar';
import './ReportsStyle.scss';
import { getBhaReportsApi, getCasingReportsApi, getRiserReportsApi, getTimeReportsApi } from '../../services/reports';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField, ThemeProvider } from '@mui/material';
import { getSelecaoPlataformasApi, IPlat } from '../../services/plataforma';
import moment from 'moment';
import ReportGroup from '../../components/GraphGroup/GraphGroup';
import ReportTimeGraph from '../../components/GraphGroup/components/ReportTimeGraph/ReportTimeGraph';
import { iChartdata, iStatebha, iStatecasing, iStateriser, StateName } from '../../@types/reportgraphs.type';
import { appTheme } from '../../styles/tema';

const Reports: React.FC = () => {
  const whitecolor = "#FFF"
  const axis: iChartdata = { x: [], y: [], bar_colors: [], voptimum_target: 0, vstandard_target: 0, vpoor_target: 0, label_list: [], operation_name: [] }
  const eventtypes = {
    slip_to_slip: axis,
    pipe_move: axis,
    gross_speed: axis
  }
  const initial_bha = {
    open_hole: eventtypes,
    cased: eventtypes,
    drilling: eventtypes,
  }
  const initial_casing = {
    settlement: eventtypes,
    casing: eventtypes,
    belowshoedepth: eventtypes
  }
  const initial_riser = {
    ascend: eventtypes,
    descend: eventtypes,
  }
  const initial_time = axis
  const [databha, setDatabha] = React.useState<iStatebha>(initial_bha)
  const [datacasing, setDatacasing] = React.useState<iStatecasing>(initial_casing)
  const [datariser, setDatariser] = React.useState<iStateriser>(initial_riser)
  const [datatime, setDatatime] = React.useState<iChartdata>(initial_time)
  const [startdate, setStartdate] = React.useState<string>(moment().format('YYYY-MM-DDTHH:mm'))
  const [enddate, setEnddate] = React.useState<string>(moment().format('YYYY-MM-DDTHH:mm'))
  const [plataformalist, setPlataformalist] = React.useState<IPlat[]>([])
  const [selectedplataforma, setSelectedplataforma] = React.useState<string | null>('')
  const getSelecao = () => {
    getSelecaoPlataformasApi().then(response => {
      setPlataformalist(response)
    })
  };
  const getReports = () => {
    let starttime = moment(startdate).format('YYYY-MM-DD HH:mm')
    let endtime = moment(enddate).format('YYYY-MM-DD HH:mm')
    getBhaReportsApi(selectedplataforma, starttime, endtime).then(res => {
      setDatabha(res.data.bha)
    })
    getCasingReportsApi(selectedplataforma, starttime, endtime).then(res => {
      setDatacasing(res.data.casing)
    })
    getRiserReportsApi(selectedplataforma, starttime, endtime).then(res => {
      setDatariser(res.data.riser)
    })
    getTimeReportsApi(selectedplataforma, starttime, endtime).then(res => {
      setDatatime(res.data.time)
    })
  }
  React.useEffect(() => {
    getSelecao()
  }, [])

  return (
    <ThemeProvider theme={appTheme} >


      <div className="reports vh-100">
        <NavBar />
        <div className="d-flex text-white pl-3 py-1">
          XPD {'>'} {selectedplataforma} {'>'} reports

        </div>
        <div className="d-flex">
          <div className="d-flex flex-1 vh-80 flex-column">
            <div className='d-flex w-100 p-2 text-white bg-cinza' style={{ alignItems: "center", placeContent: "center" }}>


              <FormControl sx={{ minWidth: 200, m: 2 }}>
                <InputLabel id="demo-simple-select-label" sx={{ color: '#FFF' }}>Rig</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedplataforma}
                  label="Rig"
                  onChange={(e) => { setSelectedplataforma(e.target.value) }}
                  sx={{
                    svg: { color: whitecolor }
                  }}
                  style={{ color: whitecolor }}
                >
                  {plataformalist.map(plataforma => (
                    <MenuItem value={plataforma.db_name} key={plataforma.name}>{plataforma.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                id="datetime-local"
                label="Start Date"
                type="datetime-local"
                value={startdate}
                onChange={(e) => { setStartdate(e.target.value) }}
                sx={{
                  svg: { color: whitecolor },
                  input: { color: whitecolor },
                  label: { color: whitecolor },
                  m: 2
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                id="datetime-local"
                label="End Date"
                type="datetime-local"
                value={enddate}
                onChange={(e) => { setEnddate(e.target.value) }}
                sx={{
                  svg: { color: whitecolor },
                  input: { color: whitecolor },
                  label: { color: whitecolor },
                  m: 2
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <Button variant="contained" onClick={() => { getReports() }}>
                Generate report
              </Button>
            </div>

            {/* Gráficos BHA - Open Hole */}
            <ReportGroup
              data={databha}
              startdate={startdate}
              enddate={enddate}
              title={'BHA - Open Hole'}
              state={StateName.open_hole}
            />
            {/* Gráficos BHA - Cased */}
            <ReportGroup
              data={databha}
              startdate={startdate}
              enddate={enddate}
              title={'BHA - Cased'}
              state={StateName.cased}
            />
            {/* Gráficos BHA - Drilling */}
            <ReportGroup
              data={databha}
              startdate={startdate}
              enddate={enddate}
              title={'BHA - Drilling'}
              state={StateName.drilling}
            />
            {/* Gráficos Casing - Settlement */}
            <ReportGroup
              data={datacasing}
              startdate={startdate}
              enddate={enddate}
              title={'Casing- Settlement String'}
              state={StateName.settlement}
            />
            {/* Gráficos Casing - Casing */}
            <ReportGroup
              data={datacasing}
              startdate={startdate}
              enddate={enddate}
              title={'Casing'}
              state={StateName.casing}
            />
            {/* Gráficos Casing - BelowShoeDepth(Open Hole) */}
            <ReportGroup
              data={datacasing}
              startdate={startdate}
              enddate={enddate}
              title={'Casing - Open Hole'}
              state={StateName.belowshoedepth}
            />
            {/* Gráficos Riser - Ascend */}
            <ReportGroup
              data={datariser}
              startdate={startdate}
              enddate={enddate}
              title={'Riser - Ascent'}
              state={StateName.ascend}
            />
            {/* Gráficos Riser - Descend */}
            <ReportGroup
              data={datariser}
              startdate={startdate}
              enddate={enddate}
              title={'Riser - Descend'}
              state={StateName.descend}
            />
            {/* Gráficos Time*/}
            <ReportTimeGraph
              data={datatime}
              startdate={startdate}
              enddate={enddate}
              title={'Time Operations'}
            />
          </div>
        </div>
      </div>
    </ThemeProvider>
  )
}

export default Reports