import React from "react";
import Plot from "react-plotly.js";
import { iReportGroup } from "../../../../@types/reportgraphs.type";
import PageTitle from "../../../PageTitle/PageTitle";
import './ReportTimeGraphStyle.scss';

const ReportTimeGraph: React.FC<iReportGroup>=({data, startdate, enddate, title, exibe_grafico_avg})=>{
    const verifica_dados_in_range_time = (): boolean => {
        let datas_time = data['x'] || []
        let datas_verificadas_time = datas_time.some((d: number) => {
            return new Date(d) >= new Date(startdate) && new Date(d) <= new Date(enddate)
        })
        return datas_verificadas_time
    }
    const create_custom_label=(operation_name:string[], startbitdepth: number[])=>{
        const array_final=operation_name.map((value: string, indice: number)=>[value, startbitdepth[indice]]
        )
        return array_final
    }
    return(<div className="BreakGraficos"><>
        {verifica_dados_in_range_time()? (<>
        <div style={{marginBottom:'15px', width:'100%', height:'50px'}}>
        <PageTitle title={title}/>
        </div>
        <div className="Graficos TimeGraph flex-78 mx-3 d-flex">
            
        <div className="SideLabel Sidelabel-time cinza-claro">
            <span>
            Time<br></br>
            (minutes)
            </span></div>
            <div className="Grafico flex-1 d-flex flex-column">
            <Plot
                data={[
                {
                    type: 'scatter',
                    x: ['2000-07-01', '2099-07-01'],
                    y: [data['vaverage_target'], data['vaverage_target']],
                    mode: 'lines',
                    name: "vaverage: " + data['vaverage_target'],
                    line: {
                    width: 2,
                    color: 'rgb(160, 160, 160)',
                    dash: 'dash'
                        }
                },
                {
                    type: 'scatter',
                    x: ['2000-07-01', '2099-07-01'],
                    y: [data['voptimum_target'], data['voptimum_target']],
                    mode: 'lines',
                    name: "voptimum: " + data['voptimum_target'],
                    line: {
                    width: 2,
                    color: 'rgb(137, 207, 240)',
                    dash: 'dash'
                        }
                },
                {
                    type: 'scatter',
                    x: ['2000-07-01', '2099-07-01'],
                    y: [data['vstandard_target'], data['vstandard_target']],
                    mode: 'lines',
                    name: "vstandard: " + data['vstandard_target'],
                    line: {
                    width: 2,
                    color: 'rgb(208, 166, 20)',
                    dash: 'dash'
                            }
                    },
                    {
                    type: 'scatter',
                    x: ['2000-07-01', '2099-07-01'],
                    y: [data['vpoor_target'], data['vpoor_target']],
                    mode: 'lines',
                    name: "vpoor: " + data['vpoor_target'],
                    line: {
                    width: 2,
                    color: 'rgb(191, 2, 2)',
                    dash: 'dash'
                            }
                    },
                {type: 'bar',
                x: data['x'],
                y: data['y'],
                width: 300000,
                showlegend: false,
                marker:{
                    color: data['bar_colors'],
                    line: {
                      color: 'rgb(0,0,0)',
                      width: 0.5
                    }
                        },
                text: data['label_list'],
                textposition: 'none',
                textfont: {
                    size: 28,
                },
                customdata: create_custom_label(data['operation_name'] || [] ,data['startbitdepth'] || []),
                hovertemplate:'<b>%{customdata[0]}</b>' +
                              `<br><b>Start bit depth</b>: %{customdata[1]}` +
                              '<br><b>Start time</b>: %{x}<br>' +
                              '<b>Duration(min)</b>: %{text}<extra></extra>'
                },
                ]}
                config={{
                    displaylogo: false,
                    responsive: true}}
                layout={ {
                legend: {font:{size:15}},
                barmode: 'relative',
                height: 250,
                bargap: 0,
                bargroupgap: 0,
                colorway: ['#1f77b4'],
                margin: {
                    t: 50,
                    b: 50,
                    l: 40,
                    r: 20},
                xaxis:
                {
                    autorange: false,
                    range: [startdate, enddate],
                    tickfont: {
                        size: 16,
                      },
                    dtick: 7200000,
                },
                yaxis:
                {
                    autorange: false,
                    range: [0, data['vstandard_target']*4 ],
                    tickfont: {
                        size: 16,
                      },
                },
                    
                shapes: [
                    // # Line Horizontal Voptimum
                    {
                        'type': 'line',
                        'x0': 0,
                        'y0': data['voptimum_target'],
                        'x1': '2099-07-01',
                        'y1': data['voptimum_target'],
                        'line': {
                            'color': 'rgb(137, 207, 240)',
                            'width': 2,
                            'dash': 'dash'
                        },
                    },
                    // # Line Horizontal Vstandard
                    {
                    'type': 'line',
                    'x0': 0,
                    'y0': data['vstandard_target'],
                    'x1': '2099-07-01',
                    'y1': data['vstandard_target'],
                    'line': {
                        'color': 'rgb(208, 166, 20)',
                        'width': 2,
                        'dash': 'dash'
                    },
                    },
                // # Line Horizontal Vpoor
                    {
                    'type': 'line',
                    'x0': 0,
                    'y0': data['vpoor_target'],
                    'x1': '2099-07-01',
                    'y1': data['vpoor_target'],
                    'line': {
                        'color': 'rgb(191, 2, 2)',
                        'width': 2,
                        'dash': 'dash'
                            },
                    }
                ],
                } }
                />
            {exibe_grafico_avg?
            <Plot
            data={[
              {
                type: 'scatter',
                x: ['2000-07-01', '2099-07-01'],
                y: [data['avg_y'][0], data['avg_y'][0]],
                mode: 'lines',
                name: "average: " + data['avg_y'][0],
                line: {
                 width: 2,
                 color: 'rgb(160, 160, 160)',
                 dash: 'dash'
                       }
               },
              // Horizontal Line Day Shift Avg
              {
               type: 'scatter',
               x: ['2000-07-01', '2099-07-01'],
               y: [data['avg_y'][1], data['avg_y'][1]],
               mode: 'lines',
               name: "day shift avg: " + data['avg_y'][1],
               line: {
                width: 2,
                color: 'rgb(255, 127, 14)',
                dash: 'dash'
                      }
              },
              // Horizontal Line Night Shift Avg
              {
                type: 'scatter',
                x: ['2000-07-01', '2099-07-01'],
                y: [data['avg_y'][2], data['avg_y'][2]],
                mode: 'lines',
                name: "night shift avg: " + data['avg_y'][2],
                line: {
                  width: 2,
                  color: 'rgb(133, 81, 189)',
                  dash: 'dash'
                }
              },
              // Horizontal Line Voptimum
              {
                type: 'scatter',
                x: ['2000-07-01', '2099-07-01'],
                y: [data['voptimum_target'], data['voptimum_target']],
                mode: 'lines',
                name: "voptimum: " + data['voptimum_target'],
                line: {
                 width: 2,
                 color: 'rgb(45, 138, 224)',
                 dash: 'dash'
                       }
               },
               {
                type: 'bar',
                x: data['avg_x_list'],
                y: data['avg_y_list'],
                width: 2000000,
                showlegend: false,
                marker:{
                  color: 'rgb(94, 94, 94)',
                  line: {
                    color: 'rgb(0,0,0)',
                    width: 0.5
                  }
                      },
                customdata: data['qty_list'],
                text: data['avg_y_label'],
                textposition: 'outside',
                texttemplate: '%{customdata}',
                textfont: {
                  size: 18,
                },
                
                
                hovertemplate:
                              '<br><b>Hour</b>: %{x}<br>' +
                              `<b>Duration(minn)</b>: %{text}<extra></extra>`+
                              '<br><b>Quantity</b>: %{customdata}'
                },
            ]}
            config={{
              displaylogo: false,
              responsive: true
            }}
            layout={{
              legend: {font:{size:15}},
              barmode: 'relative',
              showlegend: true,
              height: 250,
              bargap: 0,
              bargroupgap: 0,
              colorway: ['#1f77b4'],
              margin: {
                t: 50,
                b: 50,
                l: 40,
                r: 20},
                xaxis:
                {
                  autorange: false,
                  range: [startdate, enddate],
                  tickfont: {
                    size: 16,
                  },
                  dtick: 7200000,
                },
                yaxis:
                {
                  autorange: false,
                  range: [0, data['avg_y'][0]*3],
                  tickfont: {
                    size: 16,
                  },
                
                },
                  
                  
            } }
            />
              :<></>}
            </div>  
        </div>
        </>):<></>}
</>
</div>
)
}

export default ReportTimeGraph