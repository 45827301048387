import React from "react";
import './NavBarStyle.scss';
import RzxLogo from '../../assets/images/logo1.png';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const NavBar: React.FC<{}> = () =>{
    const navigate = useNavigate()
    
    const navigateToSelectPlatform = () => {
        navigate('/')
    }
    const navigateToSelectWells = () => {
        navigate('/wells')
    }
    const navigateToSelectReports = () => {
        navigate('/reports')
    }
    const navigateToSelectDailyReport = () => {
        navigate('/daily_report')
    }
    const [params] = useSearchParams()
    return (
        <div className="flex-column">
            <div className="navbar">
                <img src={RzxLogo} alt="Rzx"/>
                <ul>
                    <li>
                        <a className="pointer" onClick={navigateToSelectPlatform}> Rigs</a>
                    </li>
                    <li>
                        <a className="pointer" onClick={navigateToSelectWells}> Wells</a>
                    </li>
                    <li>
                        <a className="pointer" onClick={navigateToSelectReports}> Reports</a>
                    </li>
                    <li>
                        <a className="pointer" onClick={navigateToSelectDailyReport}> Daily Report</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default NavBar;