import {api} from './api';

// DadosPlataforma
export const getReading = (plataforma: string | null): Promise<any> => {
  return api.get('dadosplataforma/', {params:{plataforma}}).then(res=>{
    
    const {bitdepth, depth, last_update, update_status = 0, sections = []} = res.data
    const estagios = sections.reverse().map((section: any) => {
      const cores = Object.keys(section.Content).map((key: string)=> (
        {estagio: key, altura: section.Content[key], sessao:section.section}
        ))
      return cores

    })
    const now = new Date()
    if (update_status == null){
      const dados = {
        last_update, //new Date(timestamp).toLocaleTimeString(),
        update_status: 'No data on local database',
        current_bit_depth: bitdepth || 0,
        hole_depth: depth,
        broca: 0,
        sections: sections.reverse()
      }
      return {...res.data, estagios, ...dados}
    }

    const ts = new Date(now.getTime() - update_status*1000);
    var delta = Math.abs(now.getTime() - ts.getTime()) / 1000;
    
    
    // calculate (and subtract) whole days
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    var minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;
    
    var seconds = Math.floor(delta);
    delta -= seconds
    // what's left is seconds
    //var seconds = Math.trunc(delta % 60);
    let update = 'Last update '
    
    if (days>0){
      update += days+' days, ' +hours+' hours'
    }
    else if (hours>0 && days <0){
      update += hours+' hours, ' +minutes +' minutes'
    }
    else if (minutes>0 && hours<0 && days<0){
      update += minutes+' minutes, ' +seconds+' seconds'
    }
    else {
      update += seconds+' seconds'
    }
    update += ' ago'
    //const update_status = `Last update ${days? days+' days and '+minutes+' minutes':minutes+' minutes'} ago`
    const dados = {
      last_update, //new Date(timestamp).toLocaleTimeString(),
      update_status: update,
      current_bit_depth: bitdepth || 0,
      hole_depth: depth,
      broca: 0,
      sections: sections.reverse()
    }
    return {...res.data, estagios, ...dados}
  })
  // .catch(err => {
  //   return new Error("Whoops!");
  // })
}