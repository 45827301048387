import React from 'react';
import {useNavigate} from 'react-router-dom';
import { Box, Button, styled, Table, TableBody, TableHead, TableRow, ThemeProvider } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { appTheme } from '../../styles/tema';
import './GestaoPocosStyles.scss';

import { iPocos } from '../../@types/plataforma.type';
import { useSearchParams } from 'react-router-dom';
import BoxLogo from '../../components/BoxLogo/BoxLogo';
import WellFormDialog from '../../components/WellCreateFormDialog/WellFormDialog';
import DeleteDialog from '../../components/DeleteDialog/DeleteDialog';
import { deletePocoApi, getPocoApi } from '../../services/pocos';


const GestaoPoco: React.FC = () => {
  const [pocos, setPocos] = React.useState<iPocos[]>([])
  const [pocoEdit, setPocoEdit] = React.useState<iPocos | null>(null)
  const [pocoDelete, setPocosDelete] = React.useState<iPocos | null>(null)
  const [openFormDialog, setOpenFormDialog] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState<boolean>(false);
  
  const history = useNavigate()
  const [params] = useSearchParams()

  const getPocos = () => {
    getPocoApi(params.get('plataforma')).then(res => {
      setPocos(res)
    })
  }


  /** ao fechar o Dialog, verifica se teve alteração de plataforma, se tiver verifica
  se é nova plataforma ou se foi alteração de existente e coloca essa alteração
  na lista de plataforma */
  const handleClose = (poco?: iPocos) => {
    setOpenFormDialog(false);
    if (pocoEdit && poco) {
      const newList = pocos.map(p => p.id === poco?.id ? poco : p)
      setPocos(newList)
    } else if (poco) {
      const newList = [...pocos, poco]
      setPocos(newList)
    }
  };

  const handleClickEditButton = (poco: iPocos) => {
    setPocoEdit(poco);
    setOpenFormDialog(true);
  }

  const handleDeleteButton = (poco: iPocos) => {
    setPocosDelete(poco);
    setOpenDeleteDialog(true);
  }

  /** Ao deletar, retira da lista de plataforma a plataforma exlcuida */
  const handleDeleteConfirm = (pocoToDelete: iPocos) => {
    deletePocoApi(pocoToDelete).then(() => {
      const newList = pocos.filter(p => pocoToDelete.id !== p.id)
      setPocos(newList);
      setOpenDeleteDialog(false);
    })
  }

  const StyledTableCell = styled(TableCell)(({ theme }: {theme: any}) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    }
  }));

  React.useEffect(() => {
    getPocos()
  },[]);
    
  return (
    <ThemeProvider theme={appTheme}>
      <Box className="plataformas-page">
        <BoxLogo />
        <Box sx={{width: '100%', marginBottom: 1}}>
          <Button variant="contained" onClick={()=> history(-1)} sx={{float: 'left'}}>Back</Button>
        </Box>
        <Table size="small" className="bg-branco">
          <TableHead style={{ backgroundColor: 'rgba(220, 220, 220, 0.5)'}}>
            <TableRow>
              <StyledTableCell>Well ID</StyledTableCell>
              <StyledTableCell>Well Name</StyledTableCell>
              <StyledTableCell>Client</StyledTableCell>
              <StyledTableCell>Latitude</StyledTableCell>
              <StyledTableCell>Longitude</StyledTableCell>
              <StyledTableCell>Country</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pocos.map((poco: iPocos) => (
              <TableRow key={poco.id}>
                <TableCell>{poco.well_id}</TableCell>
                <TableCell>{poco.well_name}</TableCell>
                <TableCell>{poco.client}</TableCell>
                <TableCell>{poco.latitude}</TableCell>
                <TableCell>{poco.longitude}</TableCell>
                <TableCell>{poco.country}</TableCell>
                <TableCell>
                  <Button onClick={()=>handleClickEditButton(poco)}>
                    <BorderColorIcon />
                  </Button>
                  <Button onClick={()=>handleDeleteButton(poco)} >
                    <DeleteIcon color="error" ></DeleteIcon>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <WellFormDialog
        open={openFormDialog}
        handleClose={handleClose}
        pocoEdit={pocoEdit}
        />
      <DeleteDialog 
        openDeleteDialog={openDeleteDialog}
        headerText='Delete Platform'
        bodytext={`Do you really want to delete the platform${' '+pocoDelete?.well_id||''}?`}
        onDelete={()=>pocoDelete && handleDeleteConfirm(pocoDelete)}
        onCancel={()=>setOpenDeleteDialog(false)}
      />
    </ThemeProvider>
  )
}

export default GestaoPoco;