import React from 'react';
import { connect } from 'react-redux';
import OperacaoGraficos from './components/OperacaoGraficos';
import TopDrive from './components/TopDrive';
import './CurrentOperationStyles.scss';
import Meta from './components/Meta';
import Alarme from './components/Alarme';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { iCurrentOperation } from '../../@types/currentOperation.type';

const CurrentOperation: React.FC<{currentOperation: iCurrentOperation}> = ({currentOperation}) => {
const rederIcon = (current_operation_tripin: boolean) => {
  switch (current_operation_tripin) {
    case true:
      return <ArrowDownwardIcon sx={{ fontSize: 15 }}/>
    case false:
      return <ArrowUpwardIcon sx={{ fontSize: 15 }}/>
    case null:
      return <></>
    default:
      return <></>
  }
}
  return (
    <div className="current-operation">
      <div className="border-cut d-flex">
        <div className="op-title"></div>
        <div className='bg-cinza flex-1 text-white font-small p-1 flex-center'>
          <span className='font-bold'>CURRENT OPERATION</span>
          <span className='ml-1 uppercase'> {currentOperation.current_activity}</span>
          <span> {rederIcon(currentOperation.current_operation_tripin)}</span>
          </div>
      </div>      
      <div className="infos-graficos flex-1 d-flex pt-1"> 
        <div className='flex-85 d-flex flex-column'>
          <TopDrive/>
          <Meta />
        </div>
        <div className='flex-1 d-flex flex-column'>
          <OperacaoGraficos />
          <Alarme />
        </div>        
      </div>
    </div>
  );
}

export default connect((state: {graficos: any}) => ({currentOperation: state.graficos}))(CurrentOperation);