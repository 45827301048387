import React from 'react';
import {connect} from 'react-redux'

import DadosPlataforma from '../components/DadosPlataforma/DadosPlataforma';
import DadosPoco from '../components/DadosPoco/DadosPoco';
import OperacaoTabelas from '../components/Operacao/Operacao';
import CurrentOperation from '../components/CurrentOperation/CurrentOperation';
import { AppDispatch } from '../redux/store';
import {getReading} from '../services/reading';
import {
  setAlarms, 
  setConsistency, 
  setCurrentCrew, 
  setCurrentOperation, 
  setDadosPlataforma, 
  setDadosPlataformaSections,
  setLoading,
  setMeta, 
  setOperacoes, 
  setPocoInfo, 
  setRefresh, 
  setTopdriveData,
  setWell} from '../redux/actions'
import { getTopDrive } from '../services/topdrive';
import { getSections } from '../services/section'
import { getAlarmsApi } from '../services/alarm';
import { getMetasApi } from '../services/meta';
import { useSearchParams } from 'react-router-dom';
import { getConsistencyApi, getCurrentCrewApi, getPocoInfosApi } from '../services/dadosPoco';
import { getCurrentOperationApi } from '../services/currentoperation';
import { getOperacoesApi } from '../services/operacoes';
import { getWellApi } from '../services/well';
import NavBar from '../components/NavBar/NavBar';

const Plataforma: React.FC<{dispatch: AppDispatch}> = ({dispatch}) => {
  const [params] = useSearchParams()

  const getAlarms = () => {
    dispatch(setLoading("alarme", true))
    getAlarmsApi(params.get('plataforma')).then(res => {
      dispatch(setLoading("alarme", false))
      dispatch(setAlarms(res))
    }).catch(error => {
      // TODO colocar logica se der erro no axios
    })
  }
  const getMeta = () => {
    dispatch(setLoading("meta", true))
    getMetasApi(params.get('plataforma'))
    .then(data => {
      dispatch(setLoading("meta", false))
      dispatch(setMeta(data))
    }).catch(error =>{
      // TODO colocar logica se der erro no axios
    })
  }
  
  const getDadosPlataforma = ()=>{
    dispatch(setLoading("dadosplataforma", true))
    getReading(params.get('plataforma')).then((res:any)=>{
        dispatch(setLoading("dadosplataforma", false))  
        dispatch(setDadosPlataforma({...res}))
      }).catch(err=>{
        // TODO colocar logica se der erro no axios
      })
  }

  const getPlataformaSections = () => {
    getSections(params.get('plataforma')).then(res => {
      dispatch(setDadosPlataformaSections(res))
    })
  }

  const getTopDriveData = () => {
    dispatch(setLoading("topdrive", true))
    getTopDrive(params.get('plataforma')).then(res => {
      dispatch(setLoading("topdrive", false))
      dispatch(setTopdriveData(res))
    })
  }

  const getCurrentOperation = () => {
    dispatch(setLoading("graficos", true))
    getCurrentOperationApi(params.get('plataforma')).then(res => {
      dispatch(setLoading("graficos", false))
      dispatch(setCurrentOperation(res.data))
    })
  }

  const getPocoInfo = () => {
    getPocoInfosApi(params.get('plataforma')).then(res => {
      dispatch(setPocoInfo(res.pocoinfos))
    })
  }

  const getConsistency = () => {
    dispatch(setLoading("consistency", true))
    getConsistencyApi(params.get('plataforma')).then(res => {
      dispatch(setLoading("consistency", false))
      dispatch(setConsistency(res.consistency))
    })
  }

  const getCurrentCrew = () => {
    dispatch(setLoading("currentcrew", true))
    getCurrentCrewApi(params.get('plataforma')).then(res => {
      dispatch(setLoading("currentcrew", false))
      dispatch(setCurrentCrew(res.currentcrew))
    })
  }

  const getOperacoes = () => {
    dispatch(setLoading("operacao", true))
    getOperacoesApi(params.get('plataforma')).then(res => {
      dispatch(setLoading("operacao", false))
      dispatch(setOperacoes(res))
    })
  }

  const getWell = () => {
    getWellApi(params.get('plataforma')).then(res => {
      dispatch(setWell(res))
    })
  }


  React.useEffect(() => {
    dispatch(setRefresh())
    getPocoInfo();
    getConsistency()
    getCurrentCrew()

    getDadosPlataforma();

    getAlarms();
    getMeta();

    getPlataformaSections(); 
    getTopDriveData();

    getCurrentOperation();
    getOperacoes();
    
    getWell();
    const interval = setInterval(() => {
      getDadosPlataforma();
      getTopDriveData();
      getMeta();
    }, 2000);
    
    const interval1min = setInterval(() => {
      getConsistency();
      getCurrentOperation();
    }, 1000*60*1)
    
    const interval2min = setInterval(()=>{
      getOperacoes();
      getAlarms();
      getCurrentCrew();
    }, 1000*60*2)
    
    return () => {
      clearInterval(interval)
      clearInterval(interval1min)
      clearInterval(interval2min)
    };
  });
  
  return (
    <div className="">
      <NavBar />
      <div className="d-flex text-white pl-3 py-1">
                XPD {'>'} {params.get('plataforma')} {'>'} dashboard

            </div>
      <div className="App d-flex">
        {/* Componente lateral esquerdo */}
          <DadosPlataforma />
        <div className="infos  flex-1 d-flex flex-column">
          {/* Componente do topo */}
          <DadosPoco />
          <div className='infos-body flex-1 d-flex flex-column'>
            <div className='d-flex flex-1'>
              {/* Componente central com as tabelas */}
              <OperacaoTabelas />
              <div className='d-flex flex-column flex-1 ml-1'>
                {/* Componentes da lateral direita */}
                <CurrentOperation />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect()(Plataforma);