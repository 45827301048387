import React from 'react';
import { Alert, Box, Button, CircularProgress, Collapse, Dialog, 
  Fade, FormControlLabel, IconButton, Switch, TextField, Typography 
} from '@mui/material';
import { Container } from '@mui/system';
import RzxLogo from '../../assets/images/logo1.png';
import './FormDialogStyles.scss';
import { iTimer } from '../../@types/plataforma.type';
import { createTimerApi, updateTimerApi } from '../../services/timerReports';
import Plataforma from '../../pages/Plataforma';
import CloseIcon from '@mui/icons-material/Close';

interface iFormDialog{
  open: boolean;
  handleClose: (timerForm?: iTimer)=>void;
  timerEdit?: iTimer | null
}


const TimerFormDialog: React.FC<iFormDialog> = ({open, handleClose, timerEdit}) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [timer, setTimer] = React.useState<string>('');
    const [genericError, setGenericError] = React.useState<{detail: string, hasNotAcceptable: boolean}>({detail: '', hasNotAcceptable: false});
    const [openAlert, setOpenAlert] = React.useState(true);

  const handleSendTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setTimer(value);
  }

  const handleCloseModal = (timerForm?: iTimer) => {
    setGenericError({detail: '', hasNotAcceptable: false})
    handleClose(timerForm)
  }

  const handleCatchError = (err: any) =>{
    setOpenAlert(true)
    setGenericError({detail: err.response.data.detail, hasNotAcceptable:true})
    setLoading(false);
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true)
    const data = new FormData(event.currentTarget);
    const dados: iTimer = {
      timer: data.get('send_time')?.toString() || '',
    }
    const onResolve = (res: any) => {
      setLoading(false)
      handleCloseModal(res)
    }
    if (!timerEdit){
      createTimerApi(dados).then(() => onResolve(dados)).catch(handleCatchError);
    } else {
      updateTimerApi({...dados, id: timerEdit.id}).then(() => onResolve({...dados, id: timerEdit.id})).catch(handleCatchError)
    }
  }

  React.useEffect(() =>{
    if(timerEdit){
      setTimer(timerEdit.timer)
    } else {
      setTimer('')
    }
  }, [timerEdit]);


  return (
    <Dialog open={open} onClose={()=>handleCloseModal()} className="form-dialog-user">
      <Container component="main" maxWidth="sm" className="form-container">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
        <Box className="box-img">
          <img src={RzxLogo} alt="Logo" />
        </Box>
        <Typography component="h1" variant="h5">
          Update Send timer to Daily Report
        </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: 500 }}>
          <TextField
              margin="normal"
              id="send_time"
              fullWidth
              type="time"
              label="time to send reports2"
              name="send_time"
              color="primary"
              variant="filled"
              value={timer}
              onChange={handleSendTimeChange}
              inputProps={{style: {textAlign: "center"}}}
            />
            {
                genericError.hasNotAcceptable? 
                <Collapse in={openAlert}>
                <Alert severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }>{genericError?.detail}</Alert>
                </Collapse> : <></>          
            }

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Save
              <Fade
                in={loading}
                style={{
                  transitionDelay: loading ? '300ms' : '0ms',
                  marginLeft: 10
                }}
                unmountOnExit
              >
                <CircularProgress color="inherit" size={15} />
              </Fade>
            </Button>
          </Box>
        </Box>
      </Container>
    </Dialog>
  )
}

  export default TimerFormDialog
