import React from "react";
import './GraphPieGroupPrintStyle.scss';
import GraphPie from "./GraphPie/GraphPiePrint";
import { iPieChart } from "../../@types/efficiency.type";
import GraphPiePrint from "./GraphPie/GraphPiePrint";


interface iPieGraphGroup{
    failures: iPieChart
    lessons: iPieChart

}

const GraphPieGroupPrint: React.FC<iPieGraphGroup>=({failures, lessons})=>{
    return(
        <div className="PieGraphGroupPrint d-flex flex-78 mx-3">
            {!lessons.values || lessons.values.length === 0 ? (
          <p className="NoData BreakForPrint">No suboperation on date range</p>
        ) : (
          <GraphPie
            values={lessons.values}
            labels={lessons.labels}
            text={lessons.text}
            title="SubOperations"
          />
        )}
  
        {!failures.values || failures.values.length === 0 ? (
          <p className="NoData BreakForPrint">No failures/delays on date range</p>
        ) : (
          <GraphPie
            values={failures.values}
            labels={failures.labels}
            text={failures.text}
            title="Failures/Delay"
          />
        )}
      </div>
    );
  };

export default GraphPieGroupPrint