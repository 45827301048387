import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  styled,
  Table,
  TableBody,
  TableHead,
  TableRow,
  ThemeProvider,
  IconButton,
  TextField
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ContactMailIcon from '@mui/icons-material/ContactMail';
import SearchIcon from '@mui/icons-material/Search';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarms';
import { appTheme } from "../../styles/tema";
import "./GestaoPlataformaStyles.scss";
import Tooltip from '@mui/material/Tooltip';
import { iPlataforma, iTimer } from "../../@types/plataforma.type";
import {
  deletePlataformaApi,
  getPlataformasApi,
} from "../../services/plataforma";
import { getTimerApi } from "../../services/timerReports";
import BoxLogo from "../../components/BoxLogo/BoxLogo";
import PlatformFormDialog from "../../components/PlataformCreateFormDialog/PlataformFormDialog";
import TimerFormDialog from "../../components/TimerUpdateFormDialog/TimerFormDialog";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import Pagination from "@mui/material/Pagination";
import { countPages } from "../../utils/functions";
import LinearProgress from "@mui/material/LinearProgress";

const GestaoPlataforma: React.FC = () => {
  const [platforms, setPlatforms] = React.useState<iPlataforma[]>([]);
  const [platformEdit, setPlatformEdit] = React.useState<iPlataforma | null>(
    null,
  );
  const [timerEdit, setTimerEdit] = React.useState<iTimer | null>(
    null,
  );
  const [platformDelete, setPlatformDelete] =
    React.useState<iPlataforma | null>(null);
  const [openFormDialog, setOpenFormDialog] = React.useState(false);
  const [openFormTimerDialog, setOpenFormTimerDialog] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] =
    React.useState<boolean>(false);
  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState<number>(1);
  const [loading, setLoading] = React.useState(false);
  const [SearchQuery, setSearchQuery] = React.useState<string>('')

  const navigate = useNavigate();
  const navigateToGestaoPocos = (platform: String) => {
    navigate("/wells/?plataforma=" + platform);
  };

  const navigateToManageMailing = (platform: String) => {
    navigate("/mailing/?plataforma=" + platform);
  };

  const handleClickSearch = () => {
    setPage(1)
    getPlataformas(SearchQuery)
  }

  const getPlataformas = (search?: string) => {
    setLoading(true);
    getPlataformasApi(page, search)
      .then((res) => {
        setCount(countPages(res.count));
        setPlatforms(res.results);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getTimer = () => {
    getTimerApi().then((res) => setTimerEdit(res))
  }

  const handleClickOpen = () => {
    setOpenFormDialog(true);
  };

  const handleClickTimer = () => {
    setOpenFormTimerDialog(true);
  };

  /** ao fechar o Dialog, verifica se teve alteração de plataforma, se tiver verifica
  se é nova plataforma ou se foi alteração de existente e coloca essa alteração
  na lista de plataforma */
  const handleClose = (platform?: iPlataforma) => {
    setOpenFormDialog(false);
    if (platformEdit && platform) {
      const newList = platforms.map((p) =>
        p.id === platform?.id ? platform : p,
      );
      setPlatforms(newList);
    } else if (platform) {
      const newList = [...platforms, platform];
      setPlatforms(newList);
    }
  };

  const handleCloseTimer = (timerForm?: iTimer) => {
    setTimerEdit(timerForm || timerEdit)
    setOpenFormTimerDialog(false)
  };

  const handleClickEditButton = (platform: iPlataforma) => {
    setPlatformEdit(platform);
    setOpenFormDialog(true);
  };

  const handleDeleteButton = (platform: iPlataforma) => {
    setPlatformDelete(platform);
    setOpenDeleteDialog(true);
  };

  /** Ao deletar, retira da lista de plataforma a plataforma exlcuida */
  const handleDeleteConfirm = (platformToDelete: iPlataforma) => {
    deletePlataformaApi(platformToDelete).then(() => {
      const newList = platforms.filter((p) => platformToDelete.id !== p.id);
      setPlatforms(newList);
      setOpenDeleteDialog(false);
    });
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const StyledTableCell = styled(TableCell)(({ theme }: { theme: any }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  }));

  React.useEffect(() => {
    getPlataformas();

  }, [page]);

  React.useEffect(() => {
    getTimer();
  },[])

  return (
    <ThemeProvider theme={appTheme}>
      <Box className="plataformas-page">
        <BoxLogo />
        <Box sx={{ width: "100%", marginBottom: 1 }}>
          <div style={{ float: "left"}}>
            <Button
              variant="contained"
              onClick={() => navigate(-1)}
              sx={{ float: "left" }}
              >
              Back
            </Button>
            <>
            
            <TextField
                        id="send-timer"
                        label="Send timer"
                        type="time"
                        value={timerEdit?.timer || ''}
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
              <Tooltip title="update timer" placement="top">
                <IconButton aria-label="timer" onClick={handleClickTimer}>
                  <AccessAlarmIcon />
                </IconButton>
              </Tooltip>
            </>
          </div>
          <div style={{ float: "right" }}>
            <>
              <TextField
                id="search-bar"
                className="text"
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchQuery(e.target.value);
                }}
                onKeyDown={(e) => {
                  if(e.key === "Enter"){
                    handleClickSearch()
                  }
                }}
                label="Enter plataform"
                variant="outlined"
                placeholder="Search..."
                size="small"
                value={SearchQuery}
                key='searchBar'
              />
              <IconButton aria-label="search" onClick={handleClickSearch}>
                <SearchIcon />
              </IconButton>
            </>
            <Button
              variant="contained"
              onClick={handleClickOpen}
              sx={{ float: "right" }}
            >
              New
            </Button>
          </div>
        </Box>
        <Table size="small" className="bg-branco">
          <TableHead style={{ backgroundColor: "rgba(220, 220, 220, 0.5)" }}>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Database name</StyledTableCell>
              <StyledTableCell>SSH Tunel Port</StyledTableCell>
              <StyledTableCell>Enabled</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
              <StyledTableCell>Wells</StyledTableCell>
              <StyledTableCell>Mailing</StyledTableCell>
              {/* <StyledTableCell>Send</StyledTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading ? (
              platforms.map((platform: iPlataforma) => (
                <TableRow key={platform.id}>
                  <TableCell>{platform.name}</TableCell>
                  <TableCell>{platform.db_name}</TableCell>
                  <TableCell>{platform.ssh_tunnel_port}</TableCell>
                  <TableCell>{platform.enabled ? "Yes" : "No"}</TableCell>
                  <TableCell>
                    <Button onClick={() => handleClickEditButton(platform)}>
                      <BorderColorIcon />
                    </Button>
                    <Button onClick={() => handleDeleteButton(platform)}>
                      <DeleteIcon color="error"></DeleteIcon>
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => navigateToGestaoPocos(platform.db_name)}
                    >
                      <FormatListBulletedIcon />
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => navigateToManageMailing(platform.db_name)} >
                      <ContactMailIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  {" "}
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Pagination count={count} page={page} onChange={handleChange} />
      </Box>
      <PlatformFormDialog
        open={openFormDialog}
        handleClose={handleClose}
        platformEdit={platformEdit}
        />
      <TimerFormDialog 
        open={openFormTimerDialog}
        handleClose={handleCloseTimer}
        timerEdit={timerEdit}

      />
      <DeleteDialog
        openDeleteDialog={openDeleteDialog}
        headerText="Disable Platform"
        bodytext={`Do you really want to disable the platform${" " + platformDelete?.name || ""
          }?`}
        onDelete={() => platformDelete && handleDeleteConfirm(platformDelete)}
        onCancel={() => setOpenDeleteDialog(false)}
      />
    </ThemeProvider>
  );
};

export default GestaoPlataforma;