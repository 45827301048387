import { LocalDining } from '@mui/icons-material';
import { initializeConnect } from 'react-redux/es/components/connect';
import {createStore} from 'redux';

const INITIAL_STATE = {
    loading: {
        dadosplataforma: false,
        operacao: false,
        alarme: false,
        graficos: false,
        topdrive: false,
        meta: false,
        consistency: false,
        currentcrew: false,
    },
    alarms: [],
    meta: {
        dados: {},
        porcentagem: 0
    },
    graficos: {
        cased: {error: null},
        drilling: {error: null},
        openHole: {error: null},
        current_operation_eventtype: '',
        current_operation_name: '',
        current_activity: '',
        current_operation_type: '',
        current_operation_tripin: null,
    },
    plataforma: {},
    plataformaSections: [],
    topdrive: [],
    dadospoco: {
        currentcrew: [],
        consistency: {
            current: 0,
            overall: 0,
        },
        pocoinfos: []
    },
    operacoes: [],
    well: {},
}

function reducer(state: any = INITIAL_STATE, action: any){
    if (action.type==='SET_ALARMS'){
        return {...state, alarms: action.alarms}
    }
    if (action.type==='SET_META'){
        return {...state, meta: action.meta}
    }
    if (action.type==='SET_GRAFICOS_DATA'){
        return {...state, graficos: action.graficos}
    }
    if (action.type === 'SET_DADOS_PLATAFORMA'){
        return {...state, plataforma: action.plataforma}
    }
    if (action.type === 'SET_DADOS_PLATAFORMA_SECTIONS') {
        return {...state, plataformaSections: action.sections}
    }
    if (action.type==='SET_TOPDRIVE_DATA'){
        return {...state, topdrive: action.topdrive}
    }
    if (action.type==='SET_DADOS_POCO'){
        return {...state, dadospoco: action.dadospoco}
    }
    if (action.type==='SET_CURRENT_OPERATION') {
        return {...state, graficos: action.currentOperation}
    }
    if (action.type==='SET_POCO_INFO'){
        const pocoInfos = action.pocoinfos.length ? action.pocoinfos[0] : []
        return {...state, dadospoco: {...state.dadospoco, pocoinfos: pocoInfos}}
    }
    if (action.type==='SET_CONSISTENCY'){
        return {...state, dadospoco: {...state.dadospoco, consistency: action.consistency}}
    }
    if (action.type==='SET_CURRENT_CREW'){
        return {...state, dadospoco: {...state.dadospoco, currentcrew: action.currentcrew}}
    }
    if (action.type==='SET_OPERACOES'){
        return {...state, operacoes: action.operacoes}
    }
    if (action.type==='SET_WELL'){
        return {...state, well: action.well}
    }
    if (action.type==='SET_REFRESH'){
        return INITIAL_STATE
    }
    if (action.type==='SET_LOADING'){
        const loading: {[key: string]: boolean} = {...state.loading}
        loading[action.request_name] = action.request_value
        return {...state, loading}
    }
    
    return state
}

const store = createStore(reducer)

export default store;
export type AppDispatch = typeof store.dispatch